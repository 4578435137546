<template>
  <main class="tw-grid tw-place-items-center tw-min-h-screen tw-w-full">
    <section
      class="tw-p-3 sm:tw-p-8 tw-max-w-xs sm:tw-max-w-full tw-flex tw-flex-col tw-bg-white tw-gap-5 tw-rounded-md tw-shadow">
      <router-link :to="{ name: 'landing' }">
        <img src="~@/assets/images/logo.png" alt="Sakai logo" class="mx-auto" style="height: 48px" />
      </router-link>
      <aside class="tw-text-center">
        <h1 class="tw-text-2xl sm:tw-text-3xl tw-font-semibold">
          Selamat Datang
        </h1>
        <p class="mt-1 tw-text-gray-400">
          Silahkan login dengan email dan kata sandi
        </p>
      </aside>
      <form @submit.prevent="" class="tw-flex-col tw-flex tw-gap-3">
        <div class="">
          <label class="tw-block tw-font-medium tw-mb-1" for="email">Email</label>
          <InputText id="email" type="email" v-model="email" placeholder="Email" class="tw-w-full" />
        </div>
        <div class="">
          <label class="tw-block tw-font-medium tw-mb-1" for="password">Password</label>
          <Password v-model="password" toggleMask placeholder="Password" :feedback="false" class="tw-w-full"/>
        </div>
        <div class="tw-text-right">
          <router-link :to="{ name: 'forgot-password' }" class="ml-2 font-bold no-underline cursor-pointer text-primary">
            Forgot Password?
          </router-link>
        </div>
        <div class="tw-w-full tw-overflow-hidden">
          <vue-recaptcha ref="recaptcha" style="transform: scale(.85); transform-origin: left;" class="recaptcha"
            :sitekey="sitekey" :loadRecaptchaScript="true" @verify="onVerify" @expired="onExpired">
          </vue-recaptcha>
        </div>
        <Button label="Sign In" class="w-full text-lg tw-p-3" @click="submitLogin">
        </Button>
      </form>
    </section>
  </main>
</template>

<script>
import { useCustomAuth } from "@/composables/auth";
import { ref } from "vue";
import { VueRecaptcha } from 'vue-recaptcha'
import { useToast } from "primevue/usetoast";

export default {
  setup() {
    const { login } = useCustomAuth();

    const email = ref("");
    const password = ref("");
    const checked = ref(false);
    const recaptcha_resp = ref("");
    const toast = useToast();
    const recaptcha = ref(null);

    const submitLogin = () => {
      if (!email.value || !password.value) {
        toast.add({
          severity: 'error',
          summary: 'Authentication Failed!',
          detail: 'Email/Password can not be empty!',
          life: 2000
        });
        return
      }

      if (!recaptcha_resp.value) {
        toast.add({
          severity: 'error',
          summary: 'Authentication Failed!',
          detail: 'Captcha can not empty!',
          life: 2000
        });
        return
      }

      login({
        email: email.value,
        password: password.value,
        recaptcha_resp: recaptcha_resp.value,
      });

      recaptcha.value.reset();
    };

    return { email, password, checked, login, recaptcha_resp, recaptcha, submitLogin };
  },
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      sitekey: process.env.VUE_APP_RECAPTCHA_KEY,
    };
  },
  methods: {
    onVerify: function (recaptchaToken) {
      this.recaptcha_resp = recaptchaToken;
    },
    onExpired: function () {
      this.$refs.recaptcha.reset();
      this.recaptcha_resp = '';
    },
  },
};
</script>

<style scoped>
:deep(.p-inputtext) {
  @apply tw-w-full
}
</style>
